<div id="showwarning" class="w3-modal w3-modal-theme">
  <div id="warning" class="w3-modal-content w3-padding w3-display-middle w3-center w3-border-theme w3-padding w3-animate-opacity w3-theme-l4 w3-topbar w3-bottombar w3-leftbar w3-rightbar">
    <p>{{ warning | translate: session.transparams }}</p>
    <p>{{ 'warnings.continue' | translate: session.transparams }}</p>
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-margin-right pageButton w3-round"
      name        = "submit"
      value       = "{{ 'buttons.yes' | translate: session.transparams }}"
      title       = "{{ 'buttons.yes' | translate: session.transparams }}"
      (click)     = "action(true)"
    >
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      name        = "cancel"
      value       = "{{ 'buttons.no' | translate: session.transparams }}"
      title       = "{{ 'buttons.no' | translate: session.transparams }}"
      (click)     = "cancel()"
    >
  </div>
</div>
<div *ngIf="initialized" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <omnipas-cardpreview *ngIf="session.cardpreview"></omnipas-cardpreview>
  <div class="w3-margin">
    <p *ngIf="session.transparams.customer_shortname != 'Horizon' && !session.savephotoonserver">
      <span>
        {{ 'status.description.'+list.getValue( this.session.personData, 'CardStatusID', true ) | translate: session.transparams | customInitCap }}
      </span>
      <a class="link" (click)="showInfo()" title="{{ 'welcome.cardinfo' | translate: session.transparams }}">{{ 'welcome.cardinfo' | translate: session.transparams }}</a>
    </p>
    <p *ngIf="session.transparams.customer_shortname == 'noorderpoort'" class="w3-border w3-padding w3-card">
      <span><b>{{ 'welcome.address' | translate: session.transparams | customInitCap }}</b></span>
      <br/>
      <span *ngIf="list.getValue( this.session.personData, 'Street',       true ) != ''">{{ list.getValue( this.session.personData, 'Street', true )}}&nbsp;</span>
      <span *ngIf="list.getValue( this.session.personData, 'Number',       true ) != ''">{{ list.getValue( this.session.personData, 'Number', true )}}</span>
      <span *ngIf="list.getValue( this.session.personData, 'Suffix',       true ) != ''">{{ list.getValue( this.session.personData, 'Suffix', true )}}</span>
      <br/>
      <span *ngIf="list.getValue( this.session.personData, 'PostalCode',   true ) != ''">{{ list.getValue( this.session.personData, 'PostalCode', true )}}&nbsp;</span>
      <span *ngIf="list.getValue( this.session.personData, 'City',         true ) != ''">{{ list.getValue( this.session.personData, 'City', true )}}</span>
      <br/>
      <br/>
      <span><b>{{ 'welcome.location' | translate: session.transparams | customInitCap }}</b></span>
      <br/>
      <span *ngIf="list.getValue( this.session.personData, 'LocationName', true ) != ''">{{ list.getValue( this.session.personData, 'LocationName', true )}}</span>
    </p>
    <p *ngIf="session.transparams.customer_shortname != 'Horizon' && !session.savephotoonserver">{{ 'welcome.help' | translate: session.transparams }}</p>
  </div>
  <div class="w3-margin" *ngIf="showFirstPhoto && !session.photoAgreedNotOnFirstPage">
    <input
      id          = "agreed"
      name        = "agreed"
      type        = "checkbox"
      class       = "w3-check w3-check-theme w3-margin-right"
      title       = ""
      (click)     = "photo.togglePhotoAgreed()"
      [checked]   = "photo.photoAgreed ? 'checked' : ''"
      tabindex    = "1"
    >
    <label for = "agreed">{{ 'welcome.agreed' | translate: session.transparams }}</label>
    <p *ngIf="session.transparams.customer_shortname == 'Horizon'">
      <b>Belangrijk:</b><br/>
      Je ID kaart wordt ook gebruikt ter identificatie. Bij onduidelijke of ongepaste foto's is je ID kaart niet geldig.
    </p>
    <p *ngIf="session.transparams.customer_shortname == 'Horizon'">
      <b>Let op:</b><br/>
      Na jouw toestemming kunnen wij de pas printen. Wij gebruiken jouw foto alleen voor de genoemde doeleinden.
    </p>
  </div>
  <div *ngIf="session.transparams.customer_shortname == 'Inholland'" class="w3-margin">
    <p>{{ 'welcome.ienotsupported' | translate: session.transparams }}</p>
  </div>
  <div class="w3-margin" *ngIf="!showPhotoButtons">
    <p><b>{{ 'welcome.cardinprogress' | translate: session.transparams }}</b></p>
  </div>
  <div class="w3-margin" *ngIf="showPhotoUnapproved">
    <p><b>{{ 'welcome.photounapproved' | translate: session.transparams }}</b></p>
  </div>
  <div class="w3-margin" *ngIf="showPhotoDisapproved">
    <p><b>{{ 'welcome.photodisapproved' | translate: session.transparams }}</b></p>
  </div>
  <div class="w3-margin" *ngIf="showPhotoButtons && session.uploadphoto">
    <p *ngIf="session.photorequirements">
      <a class="link"
         [disabled] = "showFirstPhoto == photo.photoAgreed ? '' : 'disabled'"
         title      = "{{ 'photorequirements.button' | translate: session.transparams }}"
         (click)    = "showPhotoRequirements()"
      >
        <i id = "icon-photorequirements" class= "w3-large fas fa-exclamation-circle"></i>&nbsp;{{ 'photorequirements.button' | translate: session.transparams }}
      </a>
    </p>
    <input
      id          = "photoUpload"
      name        = "photoUpload"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      value       = "{{ (showFirstPhoto ? 'welcome.upload.first' : 'welcome.upload.new') | translate: session.transparams }}"
      title       = "{{ (showFirstPhoto ? 'welcome.upload.first' : 'welcome.upload.new') | translate: session.transparams }}"
      (click)     = "action(false, 'upload')"
      [disabled]  = "showFirstPhoto == photo.photoAgreed || session.photoAgreedNotOnFirstPage ? '' : 'disabled'"
      tabindex    = "2"
    >
  </div>
  <div class="w3-margin" *ngIf="showPhotoButtons && session.webcam && !iOS()">
    <input
      id          = "Webcam"
      name        = "Webcam"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      value       = "{{ (showFirstPhoto ? 'welcome.webcam.first' : 'welcome.webcam.new') | translate: session.transparams }}"
      title       = "{{ (showFirstPhoto ? 'welcome.webcam.first' : 'welcome.webcam.new') | translate: session.transparams }}"
      (click)     = "action(false, 'webcam')"
      [disabled]  = "showFirstPhoto == photo.photoAgreed || session.photoAgreedNotOnFirstPage ? '' : 'disabled'"
      tabindex    = "3"
    >
  </div>
  <!--
  <p *ngIf="iOS() && session.transparams.customer_shortname == 'Horizon'">
    <b>Let op:</b><br/>
    Je kunt geen pasfoto aanleveren als je gebruik maakt van een IPhone, MacBook of IPad! Gebruik hiervoor een ander apparaat.<br/>
    Je kunt ook naar het Passenbureau op school om een pasfoto te laten maken.
  </p>
  -->
  <div class="w3-margin" *ngIf="showCardActivate && session.cardactivate">
    <input
      id          = "ActivateCard"
      name        = "ActivateCard"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      value       = "{{ 'welcome.activatecard' | translate: session.transparams }}"
      title       = "{{ 'welcome.activatecard' | translate: session.transparams }}"
      (click)     = "activateCard()"
      tabindex    = "6"
    >
  </div>
  <div class="w3-margin" *ngIf="showCardBlock && session.cardblock">
    <input
      id          = "BlockCard"
      name        = "BlockCard"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      value       = "{{ 'welcome.blockcard' | translate: session.transparams }}"
      title       = "{{ 'welcome.blockcard' | translate: session.transparams }}"
      (click)     = "blockCard()"
      tabindex    = "7"

    >
  </div>
  <div class="w3-margin" *ngIf="showCardDeblock && session.carddeblock">
    <input
      id          = "DeblockCard"
      name        = "DeblockCard"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      value       = "{{ 'welcome.deblockcard' | translate: session.transparams }}"
      title       = "{{ 'welcome.deblockcard' | translate: session.transparams }}"
      (click)     = "deblockCard()"
      tabindex    = "8"

    >
  </div>
  <div class="w3-margin" *ngIf="showCardRequest && session.cardrequest">
    <input
      id          = "CardRequest"
      name        = "CardRequest"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      value       = "{{ 'welcome.requestcard' | translate: session.transparams }}"
      title       = "{{ 'welcome.requestcard' | translate: session.transparams }}"
      (click)     = "requestCard()"
      tabindex    = "9"

    >
  </div>
  <div class="w3-margin" *ngIf="session.digitalcard && this.list.getValue( this.session.personData, 'CardStatusID', true ) == '5'">
    <input
      id          = "DigitalCard"
      name        = "DigitalCard"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      value       = "{{ 'welcome.digitalcard' | translate: session.transparams }}"
      title       = "{{ 'welcome.digitalcard' | translate: session.transparams }}"
      (click)     = "digitalCard()"
      tabindex    = "10"
    >
  </div>
  <div class="w3-margin" *ngIf="session.nameoncard">
    <input
      id          = "NameOnCard"
      name        = "NameOnCard"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      value       = "{{ 'welcome.nameoncard' | translate: session.transparams }}"
      title       = "{{ 'welcome.nameoncard' | translate: session.transparams }}"
      (click)     = "nameOnCard()"
      tabindex    = "10"
    >
  </div>
  <div class="w3-margin" *ngIf="session.changepublishpermission">
    <input
      id          = "PublishPermission"
      name        = "PublishPermission"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      value       = "{{ 'welcome.photopermissions' | translate: session.transparams }}"
      title       = "{{ 'welcome.photopermissions' | translate: session.transparams }}"
      (click)     = "photopermissions()"
      tabindex    = "10"
    >
  </div>
  <div class="w3-margin" *ngIf="session.licenseplate">
    <input
      id          = "LicensePlate"
      name        = "LicensePlate"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      value       = "{{ 'welcome.licenseplate' | translate: session.transparams }}"
      title       = "{{ 'welcome.licenseplate' | translate: session.transparams }}"
      (click)     = "licensePlate()"
      tabindex    = "10"
    >
  </div>
  <div class="w3-margin" *ngIf="session.chargingcard">
    <input
      id          = "ChargingCard"
      name        = "ChargingCard"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      value       = "{{ 'welcome.chargingcard' | translate: session.transparams }}"
      title       = "{{ 'welcome.chargingcard' | translate: session.transparams }}"
      (click)     = "chargingcard()"
      tabindex    = "11"
    >
  </div>
  <div class="w3-margin" *ngIf="session.logoutbutton">
    <input
      id          = "Logout"
      name        = "Logout"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      value       = "{{ 'header.logout' | translate: session.transparams }}"
      title       = "{{ 'header.logout' | translate: session.transparams }}"
      (click)     = "logout()"
      tabindex    = "12"
    >
  </div>
  <div *ngIf="session.transparams.customer_shortname == 'Inholland'" class="w3-margin">
    <p *ngIf="list.getValue( this.session.personData, 'CardholderProfile', true ) == 'student'">
      <b>{{ 'welcome.student_help_b' | translate: session.transparams }}</b><br/>
      <span>{{ 'welcome.student_help_t' | translate: session.transparams }} <a href="mailto:csa@inholland.nl">csa@inholland.nl</a></span>
    </p>
    <p *ngIf="list.getValue( this.session.personData, 'CardholderProfile', true ) != 'student'">
      <b>{{ 'welcome.employee_help_b' | translate: session.transparams }}</b><br/>
      <span>{{ 'welcome.employee_help_t' | translate: session.transparams }} <b>servicepunt.</b>{{ 'welcome.employee_help_e' | translate: session.transparams }}<b>.inholland.nl</b></span>
    </p>
  </div>
</div>
