export const environment = {
  production: true,
  apiurl: 'https://leijgraaf.mijnomnipas.nl/soap/',
  //apiurl: 'https://localhost/omnicard/angular/omnicard/apps/php/',
  apitype: 'php',
  uselog: true,
  configfile: 'leijgraaf',
  encrypt: false,
  config: {}
};
